import React from "react";
import theme from "theme";
import { Theme, Box, Strong, Em, Text, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"boucheron"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px">
			<Override slot="linkBox7" color="white" />
			<Override slot="text17" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box min-width="100px" min-height="100px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="36px"
				padding="50px 16px 100px 16px"
				margin="0px auto 0px auto"
				max-width="1348px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="flex-start"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--headline3"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Press release: SPhotonix and Boucheron ‘Quatre 5D memory’ ring
							<br />
						</Strong>
						<br />
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Date: 20th November 2024
							<br />
						</Em>
						{"\n\n"}
					</Text>
					<Text
						margin="0 0 0 0"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background=" #080808"
						background-clip="text"
						font="--bodyText17SemiBold"
					/>
					<Text margin="0px 0px 0px 0px" text-align="justify" font="--bodyText21Regular">
						<br />
						{"\n"}Hidden memories to last billions of years with SPhotonix 5D memory
crystal in Boucheron jewellery{" \n\n"}
						<br />
						<br />
						•{"       \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							World first
collaboration between global jewellery maison and deep tech pioneer
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"       \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Boucheron has
launched its new innovative capsule ‘Quatre 5D Memory’ ring
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"       \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Memories
nano-etched into SPhotonix quartz crystals
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						•{"       \n"}
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							5D memory
crystals can last billions of years
						</Em>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						/>
						{"\n\n"}
						<br />
						<br />
						Newark, Delaware, November 20th :{" "}
						<Link
							href="https://sphotonix.com/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							SPhotonix
						</Link>
						{" "}have collaborated with global jewellery brand,{" "}
						<Link
							href="https://www.boucheron.com/gb/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Boucheron
						</Link>
						, to provide the unique memory crystal for
its latest innovative capsule, the{" "}
						<Link
							href="https://press-boucheron.com/quatre-5d-memory-ring-innovation-capsule-38028/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							‘Quatre 5D memory’
						</Link>
						{"\n"}ring, a world-first creation fusing high tech with high end creativity and
artistry. The new piece has been revealed as part of Boucheron’s first ever New
York boutique launch. Led by Boucheron’s Creative
Director, Claire Choisne, and inspired by her childhood memories of the
seaside, the ring contains the memory of water with a unique score provided by IRCAM (the French Institute for Acoustic/Music
Research and Coordination) – Centre Pompidou nano-etched by SPhotonix into a
quartz crystal.{"\n\n"}
						<br />
						<br />
						Professor Peter Kazansky, of University of
Southampton and the Chief Science Officer of SPhotonix has developed the 5D
memory technology with his Optoelectronics Research team. 5D memory is an
optical storage process for digital data.{" "}
						<Link
							href="https://www.5dmemorycrystal.com/"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							5D Memory Crystals
						</Link>
						{" "}are created using groundbreaking technology,
where ultrafast light pulses are fired into quartz glass, using high precision,
computer-controlled laser based systems.{" \n\n"}
						<br />
						<br />
						The technology enables creation of nano
structures inside the 3 dimensions of a glass plate, further extended by an
additional 2 optical dimensions, namely birefringence and strength retardance.
In combination this enables ultra-dense storage of data, not on the surface,
but deep inside the glass plate, making it the most durable storage medium
available today.{"\n\n"}
						<br />
						<br />
						The key to reading data also involves light. By
using polarized light illumination the birefringent data points will change the
polarization of the light passing through them. By viewing these changes using
a polarized light microscope, the data can be read and translated into their
original format.{"\n\n"}
						<br />
						<br />
						Commenting on this unique collaboration, Prof.
Peter Kazansky, SPhotonix said:{"\n\n"}
						<br />
						<br />
						“The possibilities for our 5D memory crystal are
endless, however this collaboration with Boucheron, a high jewellery maison, is
perhaps the most enchanting. It’s a beautiful fusion of deep tech, music, art
and craftsmanship into a unique, collectible piece of jewellery to be treasured
for billions of years. At SPhotonix, we are delighted to have been able to
collaborate with Boucheron and support them in their creative thinking and
execution.”{"\n\n"}
						<br />
						<br />
						Hélène Poulit-Duquesne, CEO
of Maison Boucheron, said:{"\n\n"}
						<br />
						<br />
						“‘Quatre 5D Memory’ perfectly embodies Boucheron’s
vision of pushing boundaries. By succeeding in encapsulating an audio work
within a jewelry design, we are exploring new territories of expression and
opening up limitless realms of possibility. The “Quatre 5D Memory” ring becomes
the vessel that holds a memory, an emotion, that is given to future
generations.”{"\n\n"}
						<br />
						<br />
						For the Quatre ring, in part due to its size, 5D
engraving made it possible to record up to 100 megabytes of data in the
material, which will last for billions of years. This technology makes the “Quatre
5D Memory” ring a true time(less) capsule. The data is, in fact, protected by
being written within the material, rather than on its surface.{"\n\n"}
						<br />
						<br />
						This collaboration comes hot off the heels of SPhotonix and University
Southampton’s recent announcement about the{" "}
						<Link
							href="https://www.southampton.ac.uk/news/2024/09/human-genome-stored-on-everlasting-memory-crystal-.page"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							world first ever storage of the human
genome onto a 5D memory crystal that can last billions of years
						</Link>
						{" "}which garnered global interest. SPhotonix has developed from the
research led by Kazansky at the Optoelectronics
Research Centre at the University of Southampton.
					</Text>
				</Box>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Components.Footer />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});